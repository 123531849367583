import React, { Component, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import '../../node_modules/font-awesome/scss/font-awesome.scss';
import withFirebaseAuth from 'react-with-firebase-auth'
import firebase from 'firebase/app';
import firebaseApp from '../FirebaseApp'
import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import routes from "../route";
import axios from 'axios';




const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

const firebaseAppAuth = firebaseApp.auth();

const providers = {
    googleProvider: new firebase.auth.GoogleAuthProvider(),
    facebookProvider: new firebase.auth.FacebookAuthProvider()
};

class App extends Component {
    constructor(props){
        super(props);
        this.state = {
            user: null,
            done: undefined
        }
    }
    authListener() {
        firebaseApp.auth().onAuthStateChanged((user) => {
            console.log(user)
            if (user) {
                
                /*db.ref('users/' + user.uid).set({
                    username: user.displayName ? user.displayName : "",
                    email: user.email,
                    profile_picture : user.photoURL
                  });*/
                
                this.setState({ user });
            } else {
                this.setState({ user: null });
            }
            this.setState({ done: true });
        });
    }

    componentDidMount() {
        this.authListener();
    }
    render() {
        


        const menu = routes.map((route, index) => {
           
          return (route.component) ? (

              <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  app={this}
                  render={props => (
                      <route.component {...props} user={this.state.user}  />
                  )} />



          ) : (null);
        });
    

        return (
            <Aux>
                <ScrollToTop>
                    <Suspense fallback={<Loader/>}>
                        <Switch>

                           { this.state.done ? 

                                !this.state.user && this.state.done ?
                               menu
                            :
                                <Route path="/" component={AdminLayout}  user={this.state.user} app={this}/>

                                : (null)

                            }
                            


                            



                        </Switch>
                    </Suspense>
                </ScrollToTop>
            </Aux>
        );
    }
}

export default App;
/*export default withFirebaseAuth({
    providers,
    firebaseAppAuth,
})(App);*/


