import firebase from 'firebase';
import {firebaseConfig} from './firebaseConfig';

const firebaseApp = firebase.initializeApp(firebaseConfig);
const providers = {
    googleProvider: new firebase.auth.GoogleAuthProvider(),
    facebookProvider: new firebase.auth.FacebookAuthProvider()
  };
firebaseApp.providers = providers;
  
export default (firebaseApp);